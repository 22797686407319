import React from "react";
// import { Link } from "gatsby";

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import { Accordion } from "../components/site";
import { EmptyState } from "../components/feedback";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Careers = (props) => (
  <Layout>
    <Seo
      title="Careers | Join the FlexiTime team"
      description="FlexiTime is a fast growing tech company providing online staff management solutions to global businesses. With exciting new products on the way, now’s a great time to join."
      pathname={props.location.pathname}
    />
    <Container>
      <Wrapper>
        <Box stackGap={40}>
          <Row stackGap={60} alignCenter>
            <Box size={20} className="hideOnMobile">
              <Image
                filename="FlexiTimePic_Dogo.jpg"
                alt="Wanda the FlexiTime Woofer"
                rounded
                addShadow
              />
            </Box>
            <Box stackGap={30} size={60} css={{ marginTop: "0 !important" }}>
              <div className="-textCenter">
                <h1 className="-fontLarge">FlexiTime Careers</h1>
                <h2 className="-fontLight">
                  We’re always looking for talented and social people to join us
                  at FlexiTime HQ. Interested?
                </h2>
              </div>
              <Button className="primary -lg -center" to="#vacancies" atag>
                See Vacancies
              </Button>
            </Box>
            <Box size={20}>
              <Image
                filename="FlexiTimePic_Tomatoes.jpg"
                alt="Rob inspecting the tomatoes"
                rounded
                addShadow
              />
            </Box>
          </Row>
          <Row stackGap={40}>
            <Box size={33}>
              <Image
                filename="FlexiTimePic_GoldAwards2020.jpg"
                alt="Some of the team at the 2020 Gold Awards"
                rounded
                addShadow
              />
            </Box>
            <Box size={33}>
              <Image
                filename="FlexiTimePic_Xmas2020.jpg"
                alt="Christmas at FlexiTime 2020"
                rounded
                addShadow
              />
            </Box>
            <Box size={33}>
              <Image
                filename="FlexiTimePic_Beervana2020.jpg"
                alt="The team at Beervana 2020"
                rounded
                addShadow
              />
            </Box>
          </Row>
          <Row className="-textCenter" stackGap={40}>
            <Box size={33}>
              <p>
                At FlexiTime, we’re passionate about improving the lives of
                small business owners by making one of their least favourite
                tasks as easy as possible. We’re a small, supportive team who
                enjoy coming to work, building great products and having fun.
              </p>
            </Box>
            <Box size={33}>
              <p>
                We offer great training, learning and long term
                development/progression opportunities for the right people. And
                all the extras like tasty coffee, table tennis, darts, Friday
                team lunches and the occasional beer festival.
              </p>
            </Box>
            <Box size={33}>
              <p>
                We’ve got big plans and we’re moving fast. If you’ve got skills,
                a bit of initiative and a well-developed sense of humour, you
                might just fit in!
              </p>
            </Box>
          </Row>
        </Box>
      </Wrapper>
    </Container>
    <Container id="vacancies" bg="Tertiary">
      <Wrapper maxWidth={850} stackGap={80}>
        <Box stackGap={5} className="-textCenter -whiteOut">
          <h4 className="-fontPrimary -fontBold">Vacancies</h4>
          <h2 className="h1">Join the team</h2>
          <h4>
            Check out our current vacancies below. If we don’t have any
            positions for you, send us your CV anyway and tell us why you’d be a
            great addition to our team. You never know!
          </h4>
        </Box>
        <Box stackGap={20}>
          <EmptyState
            show
            message={[
              <span key={0}>
                Sorry, there are no vacancies at the moment. We'd still love to
                hear from you though! Get in touch by emailing{" "}
              </span>,
              <a key={1} href="mailto:careers@flexitime.works">
                careers@flexitime.works
              </a>,
              <span key={2}>.</span>,
            ]}
          />
          {/* <Accordion title="Growth Marketer">
            <Box stackGap={30}>
              <h3>Growth Marketer</h3>
              <div>
                <p>
                  If you love to take the lead on designing and activating
                  creative marketing plans, engaging content and imaginative
                  campaigns that deliver results, then we need to talk!
                </p>
                <p>
                  We’re looking for a top notch SaaS marketer who knows what it
                  takes to move the needle, and isn’t afraid to seek feedback,
                  change tack and optimise their approach. You’ll be pumped
                  about stepping into a role where you can take ownership and
                  grow your experience and knowledge across the marketing mix.
                  We love sharing ideas, critical thinking and suggesting new
                  and better ways of doing things.
                </p>
                <p>
                  FlexiTime is a Wellington-based SaaS business with big
                  ambitions. Our smart, creative, and social team is helping
                  connect people with rewarding work through a suite of four
                  exceptional workforce management products -{" "}
                  <a href="https://www.payhero.co.nz" target="_blank" rel="noreferrer">
                    PayHero
                  </a>
                  ,{" "}
                  <a href="https://www.droppah.com" target="_blank" rel="noreferrer">
                    Droppah
                  </a>
                  ,{" "}
                  <a href="https://www.karmly.io" target="_blank" rel="noreferrer">
                    Karmly
                  </a>{" "}
                  &{" "}
                  <a href="https://www.invoxy.com" target="_blank" rel="noreferrer">
                    Invoxy
                  </a>
                  . You’ll be working directly with the Chief Growth Officer and
                  will be a key member of our small, but mighty, marketing team.
                </p>
                <p>
                  This is a unique opportunity to play a key role in the
                  development and growth of our fast-growing tech company and
                  team as we scale up and launch into new markets.
                </p>
                <p>
                  As Growth Marketer, you’ll flex your creative muscles every
                  day to deliver great marketing across different channels.
                  You’ll be excited by the many and varied opportunities to help
                  new customers discover and adopt our products.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>Key Responsibilities</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    Develop and execute inventive marketing plans and campaigns
                    to increase brand awareness, drive leads, and ultimately
                    drive revenue and account growth.
                  </li>
                  <li>
                    Love and understand our customers to define Ideal Customer
                    Profile(s) to inform acquisition targeting, collateral
                    development and opportunities for further growth.
                  </li>
                  <li>
                    Get amongst all aspects of our marketing activities,
                    including website management, SEO, PPC, content creation,
                    email campaigns, social media, and online advertising.
                  </li>
                  <li>
                    Run experiments, measure the results and optimise and refine
                    campaigns and marketing initiatives to improve outcomes.
                  </li>
                  <li>
                    Understand and keep up-to-date with the competitive
                    landscape and identify opportunities to innovate and stand
                    out to potential customers.
                  </li>
                  <li>
                    Work with the product team to proactively communicate
                    changes and drive adoption of new features and functionality
                    that deliver more value.
                  </li>
                  <li>
                    Tell the story of how our customers love our products via
                    video and written case studies.
                  </li>
                </ul>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>That’s the role, so who are you?</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    An accomplished marketing generalist with a strong creative
                    streak.
                  </li>
                  <li>
                    Ready to take the next step in your career and excited by
                    the opportunity to grow and develop.
                  </li>
                  <li>
                    5+ years of marketing experience, preferably in the SaaS
                    industry.
                  </li>
                  <li>
                    Bachelor’s degree in marketing, business, or a related
                    field.
                  </li>
                  <li>
                    A proven track record of developing, executing and
                    optimising successful marketing campaigns.
                  </li>
                  <li>
                    A history of bringing innovative ideas to the table, being
                    open to feedback and ideas about your work and an eye for
                    good design.
                  </li>
                  <li>
                    A strong understanding of digital marketing channels and
                    optimisation techniques.
                  </li>
                  <li>
                    Excellent writing skills with an ability to adapt to our
                    brand voice and audience/personas.
                  </li>
                  <li>
                    Proven ability to build relationships with internal and
                    external stakeholders.
                  </li>
                  <li>Experience with marketing technology platforms.</li>
                  <li>
                    Ability to work in a fast-paced, dynamic environment and
                    manage multiple priorities.
                  </li>
                  <li>
                    You have a bias towards action, experimentation and
                    imaginative problem solving.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  If you are a self-starter with a passion for marketing and
                  want to be part of a fast-growing technology company, we would
                  love to hear from you. Don’t delay - we’ll be assessing and
                  progressing applications as they come in.
                </p>
                <p>
                  We’d love to see a bit of personality in your application.
                  Yes, we want to see your CV and a short summary or cover
                  letter telling us why you want to work with us (and/or a
                  portfolio if you have one), but don’t be shy to show off your
                  marketing chops and send us something you think will pique our
                  interest!
                </p>
                <p>
                  Please get in touch if you have any questions about the role,
                  or if you think you have what it takes but don’t necessarily
                  tick every requirement -{" "}
                  <a href="mailto:jake@flexitime.works">jake@flexitime.works</a>
                </p>
              </div>
              <hr />
              <Button
                className="primary -lg"
                to="https://flexitime.bamboohr.com/careers/27"
                externalLink
                atag
              >
                Apply Now
              </Button>
            </Box>
          </Accordion> */}
          {/* <Accordion title="Inside Sales Executive">
            <Box stackGap={30}>
              <h3>Inside Sales Executive</h3>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>The Company</b>
                </h4>
                <p>
                  FlexiTime is a fast-growing Wellington-based tech company
                  providing online workforce management solutions to both Kiwi
                  and global businesses.
                </p>
                <p>
                  At FlexiTime, we help businesses save time and money by
                  providing a market-leading suite of software solutions that
                  automate and simplify complex administrative tasks.
                </p>
                <p>
                  We are committed to making the most complex and admin heavy
                  tasks easy and automated, freeing business owners from the
                  day-to-day stresses of running their team so they can love
                  their business again.
                </p>
                <p>
                  With exciting new products being rolled out and the company on
                  a high growth trajectory, it’s never been a better time to
                  join this tight-knit, high performing team.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>About the role</b>
                </h4>
                <p>
                  As an Inside Sales Representative, you will be the face of
                  FlexiTime, providing that personal touch and all important
                  first interaction. Confidently representing the brand, your
                  focus will be on nurturing all inbound leads and providing
                  helpful and effective first class service.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>Key responsibilities include:</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    Making calls and following up with clients to meet their
                    needs
                  </li>
                  <li>
                    Making sure to understand the individual and unique needs of
                    each customer
                  </li>
                  <li>
                    Assisting with growing revenue by transforming leads into
                    sales, while constantly delighting customers
                  </li>
                </ul>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>What you’ll need to bring:</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    A self motivated and driven individual who constantly seeks
                    the best solutions for customers
                  </li>
                  <li>
                    Proficiency in the use of lead management software, such as
                    Intercom and Zendesk Sell
                  </li>
                  <li>
                    An excellent team player with highly developed communication
                    skills
                  </li>
                  <li>Strong attention to detail and organisation skills</li>
                </ul>
                <p>
                  Previous experience within phone based B2B selling would be
                  highly advantageous, as would experience working in a SaaS or
                  technical environment.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>What can FlexiTime offer you?</b>
                </h4>
                <p>
                  You’ll get the chance to work with a group of creative,
                  passionate and supportive individuals who love what they do.
                  The role will offer plenty of variety and scope for you to
                  further develop within the company.
                </p>
              </div>
              <hr />
              <h4>
                To apply, please contact us at{" "}
                <a href="mailto:careers@flexitime.works">
                  careers@flexitime.works
                </a>
                .
              </h4>
            </Box>
          </Accordion> */}
          {/* <Accordion title="Content Specialist">
            <Box stackGap={30}>
              <h3>Content Specialist</h3>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>About the role</b>
                </h4>
                <p>
                  FlexiTime is a fast-growing Wellington-based tech company
                  providing online workforce management solutions to both Kiwi
                  and global businesses. With exciting new products being rolled
                  out and the company on a high growth trajectory, this is your
                  chance to come in and make a real impact as part of a
                  tight-knit, high performing team.
                </p>
                <p>
                  This is an ideal role for a tech savvy marketing individual
                  who has proven creative copywriting skills to help drive the
                  next phase of FlexiTime’s business growth. Reporting to the
                  Chief Marketing Officer, you will be responsible for
                  overseeing content strategy and play a leading role in shaping
                  and delivering all communications with customers, partners and
                  everyone who interacts with our business online.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>Key responsibilities include:</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    Manage website copy and keep the content fresh and
                    SEO-optimised
                  </li>
                  <li>
                    Develop and deliver a content strategy to grow our audience
                    and position FlexiTime as experts in our field. Create
                    marketing content, including emails, landing pages, lead
                    nurturing, videos, sales collateral, blog posts, articles
                    and guides
                  </li>
                  <li>
                    Work with the product team to proactively communicate
                    information about new features and functionality and drive
                    adoption
                  </li>
                  <li>
                    Tell the story of how FlexiTime customers are succeeding
                    with their products via video and written case studies
                  </li>
                  <li>
                    Manage regular communication with existing customers and
                    partners to help turn them into raving fans
                  </li>
                  <li>
                    Work with the support team on product education and
                    onboarding to constantly improve free trial conversions
                  </li>
                  <li>
                    Understand and keep up-to-date with the competitive
                    landscape and identify opportunities to differentiate
                    FlexiTime’s messaging and stand out to potential buyers
                  </li>
                  <li>
                    Measure and optimise content performance and analyse the
                    success of campaigns and other marketing activity
                  </li>
                </ul>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>What you’ll need to bring:</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    3+ years of marketing experience, ideally in a SaaS or B2B
                    environment
                  </li>
                  <li>
                    A natural way with words and ability to writing exact and
                    compelling copy for business buyers
                  </li>
                  <li>
                    A working knowledge of writing for SEO and experience
                    conducting keyword research and using SEO guidelines to
                    optimise content
                  </li>
                  <li>Experience managing websites and digital channels</li>
                  <li>
                    Natural creativity and a desire to think outside the box
                  </li>
                  <li>A portfolio of work that shows off your skills</li>
                </ul>
                <p>
                  Previous experience with video production, PR and event
                  planning would also be highly advantageous.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>What can FlexiTime offer you?</b>
                </h4>
                <p>
                  In this newly created role, you’ll get the chance to work
                  with, and influence, a group of creative, passionate and
                  supportive individuals who love what they do. The role will
                  offer plenty of variety and challenge for you to further
                  develop your career within the company.
                </p>
              </div>
              <hr />
              <h4>
                To apply, please contact us at{" "}
                <a href="mailto:careers@flexitime.works">
                  careers@flexitime.works
                </a>
                .
              </h4>
            </Box>
          </Accordion> */}
          {/* <Accordion title="Partnership Manager">
            <Box stackGap={30}>
              <h3>Partnership Manager</h3>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>About the role</b>
                </h4>
                <p>
                  FlexiTime is a Wellington-based technology company that builds
                  software solutions to simplify employment. Across a range of
                  products and markets, we focus on streamlining and automating
                  the complex, repetitive, and tedious day-to-day requirements
                  of running a business. Our products include online payroll,
                  timesheets, invoicing, and rostering software. Everything we
                  do is designed to make it easier to run a business and to
                  remove the administrative burden on business owners and people
                  managers.
                </p>
                <p>
                  As part of the sales team, this position is responsible for
                  the management of key accounts and partners. You will ensure
                  the smooth transition from an approved partner, with a
                  particular focus on ongoing quality account management to
                  deliver strong retention and identify opportunities for
                  additional revenue.
                </p>
                <p>
                  <b>Role Objective:</b> Help drive revenue of new and existing
                  key accounts and partners (accountants and bookkeepers), by
                  maximising customer retention and growing account activity.
                </p>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>Your responsibilities will include:</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    Manage partner accounts: low level support, billing
                    enquiries, administering partner program and new partner
                    leads
                  </li>
                  <li>
                    Enterprise Account Management: Be a point of contact for
                    100+ employee companies
                  </li>
                  <li>
                    Managing the overall partner pipeline, working with partners
                    to provide FlexiTime’s products to their organisation on an
                    on-going basis
                  </li>
                  <li>
                    Working with the rest of the FlexiTime team to deliver great
                    service to partners, pulling in other team members from
                    Customer Support or Accounts to help support partners where
                    required
                  </li>
                  <li>
                    Proactively work with partners to seek out areas for growth
                    and opportunities to increase the number of clients
                  </li>
                  <li>
                    Work in collaboration with the marketing team on partner
                    comms, including regularly newsletters and partner
                    collateral
                  </li>
                  <li>
                    Regularly review and improve the partner onboarding and
                    general experience
                  </li>
                  <li>
                    Make sure our partners not only can sell the benefits of
                    PayHero to their customers but also manage key support
                    questions, so to reduce the load of enquires on our support
                    team
                  </li>
                  <li>
                    Work to improve and document current account management
                    processes and procedures with a positive, can-do attitude
                  </li>
                  <li>
                    Be a champion of our partner channel and proactively promote
                    product enhancements that benefit our partners
                  </li>
                  <li>
                    Facilitate webinars, client events and presentations, and
                    attend conferences/events as required (some domestic travel
                    may be necessary)
                  </li>
                  <li>
                    Lead the team in elevating our relationship with each
                    partner to the most influential levels in order to
                    facilitate decision making and action in areas of
                    opportunity
                  </li>
                  <li>
                    Lead the team in identifying, qualifying and delivering on a
                    pipeline of strategic partner opportunities based on
                    go-to-market, strategy and product team priorities
                  </li>
                  <li>
                    Manage, evaluate and prioritise a pipeline of strategic
                    opportunities with measurable milestones and outcomes
                  </li>
                  <li>
                    Support the team in negotiating agreements with input from
                    product, partners and key internal stakeholders
                  </li>
                  <li>
                    Respond on the go to day-to-day queries from partners with
                    timely and comprehensive solutions, whilst maintaining
                    Zendesk Sell with all client touch points
                  </li>
                  <li>
                    Utilise Zendesk Sell and gain data insights to help clearly
                    plan your partner interaction and consistently build and
                    update your partner growth plans
                  </li>
                </ul>
              </div>
              <div>
                <h4 css={{ marginBottom: "10px" }}>
                  <b>Personal Competencies:</b>
                </h4>
                <ul css={{ marginBottom: "20px" }}>
                  <li>
                    Comfortable maintaining and developing relationships
                    remotely and in-person
                  </li>
                  <li>Excellent communication skills, written and verbal</li>
                  <li>An understanding of NZ payroll would be beneficial</li>
                  <li>
                    Ability to work independently with high attention to detail
                  </li>
                  <li>Outstanding relationship management experience</li>
                  <li>
                    Establishes trust-based relationships smoothly and quickly
                  </li>
                  <li>
                    You will have a passion for developing excellent customer
                    relationships and be eager to be a part of a driven, dynamic
                    New Zealand tech business!
                  </li>
                  <li>
                    Building effective relationships across all levels of the
                    business
                  </li>
                  <li>Proven negotiation skills, persuasive ability</li>
                </ul>
              </div>
              <hr />
              <h4>
                To apply, please contact us at <a href="mailto:careers@flexitime.works">careers@flexitime.works</a>.
              </h4>
            </Box>
          </Accordion> */}
        </Box>
        <p css={{ color: "#fff" }} className="-textCenter">
          Any questions? Get in touch with us at{" "}
          <a css={{ color: "#fff" }} href="mailto:careers@flexitime.works">
            careers@flexitime.works
          </a>
          .
        </p>
      </Wrapper>
    </Container>
    <Container stackGap={60}>
      <Wrapper maxWidth={1000}>
        <Box stackGap={60}>
          <Box
            stackGap={30}
            className="-center -textCenter"
            css={{ maxWidth: "850px" }}
          >
            <Box>
              <h2 className="h1">You’ll love working at FlexiTime</h2>
              <h4>Sound good? We’d love to hear from you!</h4>
            </Box>
            <FlexButtons justify="center">
              <Button
                className="darkBlue -lg"
                to="mailto:careers@flexitime.works"
                atag
                externalLink
              >
                Introduce Yourself
              </Button>
              <Button className="grey -lg" to="/about-us">
                More About Us
              </Button>
            </FlexButtons>
          </Box>
          <Image
            filename="FlexiTime_ABV2021.jpg"
            alt="FlexiTime Team 2021 at ABV Rootfop Bar"
            rounded
            addShadow
          />
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default Careers;
